import React from 'react';
import "./index.css"
import { addIcon, footerLogo, LinkedIn } from '../../../Resource/images';
import { Col, Row } from 'antd';
import insta from '../../../Resource/Images/INSTA.svg';
import fb from '../../../Resource/Images/FB.svg';
import { commonConfig } from '../../../Util/commonConfig';


function FooterComponent() {
    return (
        <>
            <div className='main-container'>
                <Row className='custom-row' gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }} justify={'space-between'}>
                    <Col span={6} className='link-col'>
                        <div className='info-container'>
                            <a href={commonConfig.mainUrl}>
                                <img src={footerLogo} alt='blobstation' className='footer-logo'></img>
                            </a>
                            <span className='column-text custom-column-tex'>We develop software solutions by working closely with clients to understand business needs. Innovative solutions built with client's vision and our technical expertise empower the businesses in modern digital world.</span>
                            <div className='icon-container'>
                                <a href='https://www.instagram.com/blobstation/' target='_blank' rel="noreferrer"><img src={insta} alt='instagram' className='icon-footer' /></a>
                                <a href='https://www.facebook.com/blobstation/' target='_blank' rel="noreferrer" ><img src={fb} alt='fb' className='icon-footer' /></a>
                                <a href='https://www.linkedin.com/company/blobstation/' target='_blank' rel="noreferrer" ><img src={LinkedIn} alt='fb' className='icon-footer' /></a>
                            </div>
                        </div>
                    </Col>
                    <div className='link-wrapper'>
                        <Col span={6} className='link-col'>
                            <div className='info-container info-container-sub'>
                                <span className='column-heading'>Quick Links</span>
                                <div className='link-group'>
                                    <li>
                                        <a href={commonConfig.mainUrl}> Home </a>
                                    </li>
                                    <li>
                                        <a href={`${commonConfig.mainUrl}/about`}> About Us</a>
                                    </li>
                                    <li>
                                        <a href={`${commonConfig.mainUrl}/career`}> Carrer </a>
                                    </li>
                                    <li>
                                        <a href={`${commonConfig.mainUrl}/blog`}> Blog </a>
                                    </li>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} className='link-col'>
                            <div className='info-container info-container-sub'>
                                <span className='column-heading'>Services</span>
                                <div className='link-group'>
                                    <li>
                                        <a href={`${commonConfig.mainUrl}/development`}> Development </a>
                                    </li>
                                    <li>
                                        <a href={`${commonConfig.mainUrl}/services/design`}>Design</a>
                                    </li>
                                    <li>
                                        <a href={`${commonConfig.mainUrl}/services/it-staff-augmentation`}> Staff Augmentation </a>
                                    </li>
                                    <li>
                                        <a href={`${commonConfig.mainUrl}/services/cloud-devops`}> Cloud & DevOps </a>
                                    </li>
                                </div>
                            </div>
                        </Col>
                    </div>
                    <Col span={6} className='link-col'>
                        <div className='info-container'>
                            <div className='address-container'>
                                <img src={addIcon} alt='' className='icon-add' />
                                <div>
                                    <span className='column-heading1'>Ahmedabad Office</span>
                                    <div className='address-text'><span className='column-text add-column-text'>
                                        505 Satyamev Elite, Near Vakil Saheb Bridge, Ambli Bopal T Junction, Bopal, Ahmedabad 380058
                                        <br /> Email : contact@blobstation.com
                                        <br />Phone : +91 99741 91122
                                    </span>
                                    </div>
                                </div>

                            </div>
                            <div className='address-container'>
                                <img src={addIcon} alt='' className='icon-add' />
                                <div>
                                    <span className='column-heading1'> Montreal Office</span>
                                    <div className='address-text'> <span className='column-text add-column-text'>
                                        2020 rte Transcanadienne, suite #107, Dorval, Quebec, Canada H9P 2N4
                                        <br /> Email : contact@blobstation.com
                                        <br /> Phone : +1 514-710-3481
                                    </span></div>
                                </div>

                            </div>

                        </div>
                    </Col>
                </Row >
                <Row gutter={16}>
                    <Col span={6} className='link-col'>
                        <span className='footer-copyright'> Ⓒ Blobstation Inc. 2023 - All Rights Reserved.</span>
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default FooterComponent;
