import { SyncOutlined } from "@ant-design/icons";
import { Avatar, Card, Skeleton } from "antd";
import { Button } from "antd/lib/radio";
const { Meta } = Card;

const HomeComponent = ({ getUserDetails, user, loading }) => {
  return (
    <>
      {user ? (
        user.results.map((ele, index) => {
          return (
            <Card
              key={index}
              className="user-card"
              cover={<img alt="example" src={ele?.picture?.large} />}
              actions={[
                <SyncOutlined key="sync" onClick={() => getUserDetails()} />,
              ]}
            >
              <Meta
                avatar={<Avatar src={ele?.picture?.thumbnail} />}
                title={
                  ele?.name?.title +
                  " " +
                  ele?.name?.first +
                  " " +
                  ele?.name?.last
                }
                description={
                  <>
                    <Meta title={ele?.gender} description={ele?.dob?.age} />
                    <Meta
                      title="Address"
                      description={
                        ele?.location?.city +
                        " " +
                        ele?.location?.state +
                        " " +
                        ele?.location?.country +
                        " " +
                        ele?.location?.postcode
                      }
                    />
                    <Meta
                      title="Contact"
                      description={
                        ele?.email + " ," + ele?.cell + " ," + ele?.phone
                      }
                    />
                  </>
                }
              />
            </Card>
          );
        })
      ) : (
        <>
          {loading ? (
            <Card className="user-card" actions={[<SyncOutlined key="sync" />]}>
              <Skeleton loading={true} avatar active>
                <Meta
                  avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  title="Card title"
                  description="This is the description"
                />
              </Skeleton>
            </Card>
          ) : (
            <Button onClick={() => getUserDetails()}>Fetch User Details</Button>
          )}
        </>
      )}
    </>
  );
};

export default HomeComponent;
