import React from 'react';
import './index.css';
import { thank2 } from '../../../../Resource/images';

const ThankYouComponent = () => {
    return (
        <div className="thank-container">
            <div className='thank-image-group'>
                <img src={thank2} alt='thank-you' className='thank-image' />
            </div>
            <div className='thank-content'>
                <div className='content-group1'>
                    <span className='group1-text'>Thank You!</span>
                </div>
                <div className='content-group2'>
                    <span className='group2-text'>Thank you for applying to Blobstation! Your application has been received, and
                        we look forward to exploring the possibility of you joining our team!</span>
                </div>
                <div className='content-group3'>
                    <span className='group3-text'> Blobstation Job Portal</span>
                </div>
            </div>
        </div>
    )
}

export default ThankYouComponent 
