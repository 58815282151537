import axios from 'axios';
import { commonConfig } from '../Util/commonConfig';
import { getCurrentAccessToken, openNotification, logout } from '../Util/commonUtility';
import { JwtExpiryError, commonError, notFoundError } from '../Util/config';

const redirectLink = '/admin/login';
const JOB_API = (contentType) => {
    const API = axios.create({
        baseURL: commonConfig.apiUrl,
        headers: {
            'Content-Type': contentType,
            'Accept': 'application/pdf,application/msword',
        },
    });

    API.interceptors.request.use(
        (config) => {
            const token = getCurrentAccessToken();
            if (token) {
                config.headers['x-access-token'] = token;
            }
            return config;
        },
        (error) => {
            openNotification('error', 'Error', JwtExpiryError);
            return Promise.reject(error);
        }
    );

    API.interceptors.response.use(
        (response) => response,
        (error) => {
            return errorHandler(error);
        }
    );

    return API;
};

function errorHandler(error) {
    if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
            switch (error.response.status) {
                case 401:
                    setTimeout(() => {
                        window.location.replace(redirectLink);
                    }, 1000);
                    logout();
                    break;

                case 404:
                    openNotification('error', 'Error', notFoundError);
                    break;

                default:
                    openNotification('error', 'Error', commonError);
                    break;
            }
        }
        throw error
    }
    throw error
}

export default JOB_API;
