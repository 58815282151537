import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "./../../../Api/client";
import Urls from "../../../Constant/Urls";
export const getUser = createAsyncThunk("getUserData", async (_, state) => {
  let routeToCall = Urls.API_1;
  const response = await client.get(routeToCall);
  return response;
});
export const exampleSlice = createSlice({
  name: "example",
  initialState: {
    userDataStatus: "idle",
    userData: {},
  },
  reducers: {
    resetUser(state, action) {
      state.userDataStatus = "idle";
      state.userData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state, { payload }) => {
      state.userDataStatus = "loading";
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.userData = payload;
      state.userDataStatus = "succeeded";
    });
    builder.addCase(getUser.rejected, (state, { payload }) => {
      state.userDataStatus = "failed";
    });
  },
});

export const { resetUser } = exampleSlice.actions;

export default exampleSlice.reducer;
