import React, { useState } from "react";
import './adminMainLayout.scss';
import { Layout } from 'antd';
import { footerText } from "../../Util/config";
import { reloadPage } from "../../Util/commonUtility";
import Logo from '../../Resource/Images/Logo.svg';
const { Content, Footer } = Layout;


const AdminMainLayout = (props) => {

    return (<Layout className="layout-admin">
        <div className="header-group-admin">
            <div className="navbar-group-admin">
                <div className="logo-group-admin">
                    <a href="/admin/login">
                        <img className="logo-main-admin" src={Logo} alt="logo" onClick={() => {
                            reloadPage();
                        }} />
                    </a>
                </div>
            </div>
        </div>
        <Content className="site-layout-content-admin">
            <div className="site-layout-content-box-admin">{props.children}</div>
        </Content>
        <Footer className="footer-admin">
            <div className="footer-text-admin">
                {footerText}
            </div>
        </Footer>
    </Layout>)
};

export default (AdminMainLayout);