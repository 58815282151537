import './Scss/application.scss';
import AppRoute from './Routes/AppRoute';
import Notification from './Components/Common/notification';


function App() {
  return (
    <>
      <AppRoute />
      <Notification />
    </>
  );
}

export default App;
