import React, { useState } from "react";
import { Divider } from 'antd';
import './index.css';
import CommonForm from "./CommonFormComponent";
import ThankYouComponent from "./ThankYouPage";


const Card = () => {
    const [showThankYou, setShowThankYou] = useState(false);
    const queryParameters = new URLSearchParams(window.location.search);
    const designationValue = queryParameters.get("current_job_designation");

    const handleFormSubmit = () => {
        setShowThankYou(true);
    };

    return (
        <div className="card-container">
            <div>
                <div className="header-container">
                    <p className="header-heading"> Application Form </p>
                    <span className="header-subheading">{designationValue ? designationValue : "Web Developer"} </span>
                    <p className="heading-content"> Please complete the form below to apply for a position with us. </p>
                </div>
                <Divider id="header-border" />
                <div className="content-container">
                    {showThankYou ? <ThankYouComponent /> : <CommonForm onFormSubmit={handleFormSubmit} />}
                </div>
            </div>
        </div>
    )
}

export default Card;