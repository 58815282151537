import React, { useEffect, useState } from 'react';
import './index.css';
import { Card, Table, Input, Pagination } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';



const JobsComponent = (props) => {
    const { columns, pssData, pssApi, allPssData, sortTitle, sortField } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const { Search } = Input;
    const [inputValue, setInputValue] = useState("");
    const page = searchParams.get('page') || 1;
    const pageSize = searchParams.get('pageSize') || 10;
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 10
    });
    const onChange = (e) => {
        const inputValue = e.target.value;
        setInputValue(inputValue);
        setSearchParams({ searchTerm: inputValue });
        if (inputValue === '') {
            pssApi(pagination);
        }
    };

    useEffect(() => {
        if (pagination) {
            pssApi(pagination, sortField);
        }
    }, [pagination, inputValue])

    const onSearch = () => {
        pssApi(pagination);
        setInputValue('');
    }

    const onPaginationChange = (pageNumber, total) => {
        localStorage.setItem('page', pageNumber);
        localStorage.setItem('pageSize', total)
        setPagination({
            page: pageNumber,
            pageSize: total
        })

    };
    return (
        <div>
            <Card title="Job Form Details" bordered={false} style={{ width: "100%", height: "100%" }}>
                <div className='card-content'>
                    <div className='job-search'>
                        <Search
                            placeholder="search"
                            onSearch={onSearch}
                            onChange={onChange}
                            style={{
                                width: 200,
                            }}
                            enterButton
                        />
                    </div>
                    <div className='job-listing'>
                        <Table scroll={{ x: 1200 }} columns={columns} dataSource={pssData} key={pssData.map((item) => item._id)} pagination={false} showSorterTooltip={{ title: `${sortTitle}` }}
                        />
                    </div>
                    <div className='job-pagination'>
                        <Pagination
                            showQuickJumper={false}
                            pageSizeOptions={[10, 50, 100]}
                            showSizeChanger
                            total={allPssData.paginationData.totalCount}
                            current={allPssData.paginationData.currentPage}
                            onChange={onPaginationChange}
                            responsive={true}
                            pageSize={allPssData.paginationData.pageSize}
                        />
                    </div>
                </div>
            </Card>
        </div>
    )
}


export default JobsComponent;


