import React, { useState } from "react";
import { Menu, Layout } from "antd";
import { UnorderedListOutlined, } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons"
import Logo from '../../Resource/Images/Logo.svg';
import SLogo from '../../Resource/Images/Small-Logo.svg'
const { Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("All Job Details", "3", <UnorderedListOutlined />),
  getItem("Logout", "2", <LogoutOutlined />,)

];
const SideBarMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  let navigate = useNavigate();

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme="light"
    >
      <div className="demo-logo-vertical">
        <img
          className={collapsed ? "small-logo" : "logo-admin text-center"}
          src={collapsed ? SLogo : Logo}
          alt="logo"
          onClick={() => {
            navigate("/admin/jobs");
          }}
        />
        {/* <Divider className="divider" /> */}
        {/* <UserProfile collapsed={collapsed} /> */}
        {/* <Divider className="divider" /> */}
      </div>

      <Menu
        theme="light"
        defaultSelectedKeys={["3"]}
        mode="inline"
        items={items}
        onClick={(item, key, keyPath, domEvent) => {
          switch (item.key) {
            case "3":
              break;
            case "2":
              localStorage.removeItem("userdata");
              navigate("/admin/login");
              break;

            default:
              break;
          }
        }}
      />
    </Sider>
  );
};
export default SideBarMenu;
