import React, { useState } from 'react';
import './Navbar.css';
import { CloseOutlined, MenuOutlined, DownOutlined } from '@ant-design/icons';
import Submenu from './submenu';
import { commonConfig } from '../../../Util/commonConfig';

function NavbarComponent() {
    const [expanded, setExpanded] = useState(false);
    const [subMenuVisible, setSubMenuVisible] = useState(false);

    const toggleNavbar = () => {
        setExpanded(!expanded);
    };

    const unToggleNavbar = () => {
        setExpanded(expanded);
    };
    const toggleSubMenu = () => {
        setSubMenuVisible(!subMenuVisible);
    };

    return (
        <div className='main-navbar-container'>
            <div className={expanded ? "link-container active" : "link-container"}>
                <li>
                    <a href={commonConfig.mainUrl}> Home </a>
                </li>
                <li className='service-list' onClick={() => {
                    toggleSubMenu()
                }}>
                    <span className='span-list'>Services</span> <DownOutlined className='outline-icon' />
                    <div className={subMenuVisible ? 'submenu-group-active' : 'submenu-group'}>
                        {subMenuVisible && <Submenu />}
                    </div>
                </li>
                <li>
                    <a href={`${commonConfig.mainUrl}/case-studies`}>Case studies </a>
                </li>
                <li>
                    <a href={`${commonConfig.mainUrl}/about`}> About Us</a>
                </li>
                <li>
                    <a href={`${commonConfig.mainUrl}/blog`}> Blog  </a>
                </li>
                <li>
                    <a href={`${commonConfig.mainUrl}/contact-us`}> Contact Us </a>
                </li>
                <div className='button-container-navbar'>
                    <button className='nav-button'><a href={`${commonConfig.mainUrl}/contact-us`}>Let's Talk</a></button>
                </div>
            </div >

            <div className="hamburger" onClick={toggleNavbar}>
                {expanded ? (
                    <CloseOutlined className='navbar-icon' />
                ) : (
                    <MenuOutlined className='navbar-icon' onClick={unToggleNavbar} />
                )}
            </div>
        </div >
    )
}

export default NavbarComponent
