import Login from "../../../Components/Admin/Login";
import React from "react";
import { useNavigate } from "react-router-dom";
import JOB_API from "../../../Api/api";
import { loginValidationError } from "../../../Util/config";
import { openNotification } from "../../../Util/commonUtility";
import Urls from "../../../Constant/Urls";


const LoginContainer = () => {
    let navigate = useNavigate();
    const authRequest = async (Data) => {
        try {
            const response = await JOB_API('application/json').post(Urls.AUTH_API, Data);
            if (response.data && response.status === 200) {
                localStorage.setItem('userdata', JSON.stringify(response.data));
                localStorage.setItem('JWT', JSON.stringify(response.data.token))
                navigate("/admin/jobs");
            } else {
                openNotification('error', 'Error', loginValidationError)
            }
        } catch (error) {
            console.log(error);
            openNotification('error', 'Error', loginValidationError)
        }
    }

    const onFinish = async (values) => {
        const Data = {
            username: values.username,
            password: values.password
        }
        await authRequest(Data);

    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return <Login
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
    />
}
export default LoginContainer;