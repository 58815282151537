import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./../Screens/Home"
import Dashboard from "./../Screens/Dashboard"
import Notfound from "../Screens/ErrorPages/notFound";
import SigninScreen from "../Screens/Auth/signin";
import LoginScreen from "../Screens/Admin/LoginScreen";
import ApplicationFormScreen from "../Screens/JobForm";
import AdminHomeScreen from "../Screens/Admin/AdminHomeScreen";
import JobScreen from "../Screens/Admin/Jobs";
import ViewScreen from "../Screens/Admin/ViewScreen";

const AppRoute = (props) => {
    return (<>
        <Routes>
            <Route path={"/admin/login"} element={<LoginScreen />} />
            <Route path={"/admin/jobs"} element={<JobScreen />} />
            <Route path={"/admin/home"} element={<AdminHomeScreen />} />
            <Route path={"/admin/jobs/view"} element={<ViewScreen />} />
            <Route path={"/"} element={<ApplicationFormScreen />} />
            <Route path={"/dashboard"} element={<Dashboard />} />
            <Route path={"/signin"} element={<SigninScreen />} />
            <Route path={"*"} element={<Notfound />} />
        </Routes>
    </>);
}

export default (AppRoute);