import React from 'react'

function AdminHome() {
    return (
        <div>
            asdflajsdklfj
        </div>
    )
}

export default AdminHome
