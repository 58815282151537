import React from 'react';
import Card from './Card';
import './index.css';

export default function ApplicationFormComponent() {
  return (
    <div className='form-container'>
      <Card />
    </div>
  )
}
