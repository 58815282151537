// A tiny wrapper around fetch(), borrowed from

import { domain } from "../Constant/Urls";

// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
export async function client(endpoint, { body, ...customConfig } = {}) {
  const headers = { "Content-Type": "application/json" };
  const blob = customConfig.blob ?? false;
  delete customConfig.blob;
  const config = {
    // method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }
  let data = {};
  try {
    const response = await window.fetch(domain + endpoint, config);
    if (response.status.toString().match(/^[5][0-9][0-9]$/)) {
      data.message = "Something went wrong! Please try again.";
      throw new Error(data);
    }
    if (response.status === 204) {
      let data = {
        success: true,
      };
      return data;
    }

    if (response.status === 200 && response.ok) {
      data.success = true;
    }
    try {
      if (blob && response.status === 200) {
        const filename = response.headers
          .get("content-disposition")
          .split(";")[1]
          .split("=")[1];
        const file = await response.blob();
        const url = window.URL.createObjectURL(file);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = `${filename}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        data = await response.json();
      }
    } catch (error) {
      data = { success: undefined };
    }

    if (response.ok) {
      return data;
    }

    if (response.status === 401) {
      window.location.href = "/refresh?redirectUri=" + window.location.href;
      data.unauthorized = true;
    } else {
      data.unauthorized = false;
    }
    throw new Error(data);
  } catch (err) {
    data = Object.keys(err).length !== 0 ? err : data;
    return Promise.reject(JSON.stringify(data));
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "GET" });
};
client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "POST", body });
};
client.put = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "PUT", body });
};
client.delete = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "DELETE", body });
};
