import React from 'react';
import './index.css'
import { cloudIcon, designIcon, devIcon } from '../../../Resource/images';
import { commonConfig } from '../../../Util/commonConfig';

const Submenu = () => {
    return (
        <ul className='submenu'>
            <li><a href={`${commonConfig.mainUrl}/services/design`}><img src={designIcon} alt='design' className='icon' />Design</a></li>
            <li><a href={`${commonConfig.mainUrl}/development`}><img src={devIcon} alt='develop' className='icon' />Development</a></li>
            <li><a href={`${commonConfig.mainUrl}/cloud-devops`}><img src={cloudIcon} alt='cloud' className='icon' />Cloud & DevOps</a></li>
            <li><a href={`${commonConfig.mainUrl}/staff-augmentation`}><img src={devIcon} alt='staff' className='icon' />Staff Augmentation</a></li>
        </ul>
    );
};

export default Submenu;