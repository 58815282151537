import { notification } from 'antd';


export const openNotification = (type, msg, desc) => {
    notification.open({
        message: msg,
        type: type,
        duration: 3,
        description: desc,
    });
};

export const getCurrentAccessToken = () => {
    const Token = JSON.parse(localStorage.getItem('JWT'));
    return Token || null;
};

export const logout = () => {
    localStorage.removeItem('JWT');
    localStorage.removeItem('userdata');
    return;
};

export const reloadPage = () => {
    window.location.reload();
};


export const isValidJson = (data) => {
    try {
        if (data !== null) {
            JSON.parse(data);
            return true
        }
        else {
            return false
        }
    } catch (error) {
        return false
    }
}