import React from "react";
import './mainLayout.scss';
import { Button, Layout } from 'antd';
import FooterComponent from "./Footer";
import { reloadPage } from "../../Util/commonUtility"
// import NavbarComponent from "./Navbar";
import NavbarComponent from "./Navbar/Navbar";
import Logo from '../../Resource/Images/Logo.svg';
import { commonConfig } from "../../Util/commonConfig";
const { Content, Footer } = Layout;
const MainLayout = (props) => {

    return (
        <Layout className="layout">
            <div className="header-group">
                <div className="navbar-group">
                    <div className="logo-group">
                        <a href={`${commonConfig.mainUrl}`}>
                            <img className="logo-main" src={Logo} alt="logo" onClick={() => {
                                reloadPage();
                            }} />
                        </a>
                    </div>
                    <div className="menu-group">
                        <NavbarComponent />
                    </div>
                    <div className="navbar-menu-button">
                        <Button className="nav-button"><a href={`${commonConfig.mainUrl}/contact-us`}>Let's Talk</a></Button>
                    </div>
                </div>
            </div>
            <Content className="site-layout-content">
                <div className="site-layout-content-box">{props.children}</div>
            </Content>
            <Footer className="footer">
                <div className="footer-text">
                    <FooterComponent />
                </div>
            </Footer>
        </Layout>)
};

export default (MainLayout);