export const logo = require("./Images/logo.jpg");
export const footerLogo = require("./Images/footer-logo.png");
export const thank1 = require("./Images/thank_you_1.jpg");
export const thank2 = require("./Images/thank_you_2.jpg");
export const uploadVector = require("./Images/upload-vector.jpg");
export const whiteLogo = require("./Images/small-icon.png");
export const smallLogo = require("./Images/small-icon.png");
export const addIcon = require("./Images/home-ahmd.png");
export const designIcon = require("./Images/design.png");
export const devIcon = require("./Images/develop.png");
export const cloudIcon = require('./Images/cloud.png');
export const LinkedIn = require('./Images/LinkedIn.png')