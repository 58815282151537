import React, { useState } from 'react';
import { Card } from 'antd';
import "./index.css";
import { DownloadOutlined } from '@ant-design/icons';
import { Table, Descriptions } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const ViewComponent = (props) => {
    let navigate = useNavigate();

    const { jobsApplicationData, getResumeById } = props;
    const Data = [];
    Data.push(jobsApplicationData);

    const columns = [
        {
            title: 'General Details',
            dataIndex: 'general',
            key: 'general',
            render: () => (
                <Descriptions bordered size="small" column={1} >
                    <Descriptions.Item
                        label="Name"
                        className='description-item'
                    >
                        {Data[0]?.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Email"
                        className='description-item'
                    >
                        {Data[0]?.email}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Contact No"
                        className='description-item'
                    >
                        {Data[0]?.contactNumber}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Current Job Location"
                        className='description-item'
                    >
                        {Data[0]?.currentJobLocation}
                    </Descriptions.Item>
                </Descriptions >
            ),
        },
        {
            title: 'Other Details',
            dataIndex: 'other',
            key: 'other',
            render: () => (
                <Descriptions bordered size="small" column={1}>
                    <Descriptions.Item
                        label="Experience"
                        className='description-item'
                    >
                        {Data[0]?.experience}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Current CTC"
                        className='description-item'
                    >
                        {Data[0]?.currentCtc}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Expected CTC"
                        className='description-item'
                    >
                        {Data[0]?.expectedCtc}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Current Organization"
                        className='description-item'
                    >
                        {Data[0]?.currentOrganization}
                    </Descriptions.Item>

                </Descriptions>
            ),
        },
        {
            title: 'Resume ',
            dataIndex: '_id',
            key: '_id',
            render: (text, record) => (
                <>
                          <Descriptions Descriptions bordered size="small" column={1} >
                        <Descriptions.Item
                            label="Resume"
                            className='description-item'
                        >
                            <span onClick={() => {
                                let CV = record.CV;
                                const extension = CV.split('.');
                                getResumeById(record._id, record.name, extension[1]);
                            }}>Download PDF <DownloadOutlined className='download-cv' /></span>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="Current Designation"
                            className='description-item'
                        >
                            {Data[0].currentDesignation}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="Notice Period"
                            className='description-item'
                        >
                            {Data[0]?.noticePeriod}
                        </Descriptions.Item>
                    </Descriptions>
                </>

            ),
        },
    ]

    return (

        <div className='view-main-container'>
            <Card
                title={<ArrowLeftOutlined onClick={() => {
                    navigate("/admin/jobs")
                }} />}
                bordered={false}
                style={{
                    width: "100%",
                    height: "700px",
                }}
            >
                <div className='card-content'>
                    <Table scroll={{ x: 1200 }} columns={columns} dataSource={Data} key={Data[0]._id} className='view-table' />
                </div>

            </Card>
        </div >
    )
}

export default ViewComponent;
