import React, { useEffect, useState } from "react";
import ViewComponent from "../../../Components/Admin/View";
import { openNotification } from "../../../Util/commonUtility";
import { Spin } from 'antd';
import { resumeError, resumeSuccess } from "../../../Util/config";
import { isValidJson } from "../../../Util/commonUtility";
import { useNavigate } from "react-router-dom";
import JOB_API from "../../../Api/api";
import Urls from '../../../Constant/Urls'


const ViewContainer = () => {
    let navigate = useNavigate();
    const [data, setData] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search);
    const jobId = queryParameters.get("id");
    const userData = localStorage.getItem("userdata");

    useEffect(() => {
        if (isValidJson(userData)) {
            getJobsById();
        }
        else {
            navigate('/admin/login');
        }
    }, [userData]);


    const getJobsById = async () => {
        try {
            const response = await JOB_API('application/json').get(`${Urls.JOB_API}/${jobId}`)
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getResumeById = async (id, name, ext) => {
        await JOB_API('application/json').get(`${Urls.JOB_API}/download/${id}`, {
            responseType: 'arraybuffer',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.${ext}`);
            document.body.appendChild(link);
            link.click();
            openNotification('success', 'Success', resumeSuccess)
        }).catch(error => {
            console.error("Error fetching data:", error);
            openNotification('error', 'Error', resumeError)
        });
    }


    if (!data) {
        return <div>
            <Spin tip="Loading...!!" size='large'>
                <div className="content" />
            </Spin>
        </div>
    }


    return (
        <>
            <ViewComponent jobsApplicationData={data} getResumeById={(id, name, ext) => { getResumeById(id, name, ext) }} />
        </>
    );
};

export default ViewContainer;
