import React from "react";
import { Layout } from "antd";
import { footerText } from "../../Util/config";
import "./adminLayout.scss";
import SideBarMenu from "../Common/sidebar";
const { Header, Content, Footer } = Layout;

const AdminLayout = (props) => {
  return (
    <Layout
      className="layout"
      style={{
        minHeight: "100vh",
      }}
    >
      <SideBarMenu />
      <Layout>
        <Header />
        <Content className="site-layout-content">{props.children}</Content>
        <Footer className="footer">
          {footerText}
        </Footer>
      </Layout>
    </Layout>
  )
}
export default AdminLayout;
