import React from 'react';
import "./index.css";
import { Divider } from 'antd';
import { Button, Checkbox, Form, Input } from 'antd';


const Login = (props) => {
    const { onFinishFailed, onFinish } = props;

    return (
        <div className='login-main-container'>
            <div className="login_card-container">
                <div className="login">
                    <div className="login_header-container">
                        <span className="login-heading"> Login Form </span>
                    </div>
                    <Divider id="login_header-border" />
                    <div className="login_content-container">
                        <div className="center">
                            <Form
                                name="Auth_Admin"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                style={{
                                    maxWidth: 600,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    validateTrigger={"onChange"}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your username!',
                                        },
                                    ]}
                                >
                                    <div className='common-input'>
                                        <Input />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <div className='common-input'>
                                        <Input.Password />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <div className='common-input'>
                                        <Checkbox>Remember me</Checkbox>
                                    </div>
                                </Form.Item>

                                <div className='button-group'>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 8,
                                            span: 16,
                                        }}
                                    >
                                        <Button htmlType="submit" className='submit-button'>
                                            <span className='submit-text'>Submit</span>
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;

