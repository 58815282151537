module.exports = {
  footerText: 'Ⓒ Blobstation Inc. 2023 - All Rights Reserved.',
  JwtExpiryError: 'Session expired Please relogin',
  loginValidationError: 'Invalid credentials',
  commonError: 'Something Went Wrong please refresh the page',
  loginSuccess: 'Login Successfull',
  resumeSuccess: 'Resume Download SuccesFully!!',
  resumeError: 'Error Downloading Resume!!',
  deleteSuccess: 'Data Deleted SuccessFully!!',
  deleteError: 'Error Deleting Data!!',
  formSubmit: 'Form Submited SuccessFully!!',
  notFoundError : 'Item Not Found!!'
}
